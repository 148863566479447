.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#root {
  background-color: #5c89a8;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Add this line */
  position: relative; /* Add this line */
}

p {
  font-size: 18px; /* Adjust this value to change the font size */
}

.title {
  font-size: 12px;
  margin-bottom: 15px;
}

.marketcap {
  font-size: 24px; /* Adjust as needed */
}

.burnspace {
  margin-bottom: 30px; /* Add 50px of bottom margin */
}

.section-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .section-container {
    flex-direction: column;
  }
  .pwp-image {
    display: none;
  }
}

.section {
  flex: 1;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #406775;
}

.section .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Add some space between the rows */
  font-size: 18px;
}

.section .column {
  flex: 1;
}

.button {
  background-color: #f29007;
  border: none;
  border-radius: 4px;
  color: #fff !important;
  display: inline-block;
  padding: 0px 20px;
  margin-bottom: 5px;
  text-align: center;
  text-decoration: none;
}

.full-width-section {
  width: 100%;
  margin-top: 20px;
}

.full-width-section progress {
  width: 100%;
  margin-bottom: 10px;
}

a {
  color: #ea9115 !important;
  text-decoration: underline;
  font-weight: bold;
}

.progress-section {
  width: 95%; /* Default to mobile */
  margin: 0 auto;
}

/* This will apply if the viewing area is greater than 768px */
@media (min-width: 768px) {
  .progress-section {
    width: 80%; /* Desktop */
  } 
}

.progress-bar {
  font-weight: bold;
  font-size: 19px;
  background-color: #ea9115;
}

.custom-progress-bar {
  height: 30px; /* Adjust this value to make the progress bar thicker */
  margin-bottom: 30px;
}

.custom-progress-bar2 {
  height: 30px; /* Adjust this value to make the progress bar thicker */
  margin-bottom: 30px;
}

.custom-progress-bar2 .progress-bar {
  background-color: #7d4dbb;
}

.custom-progress-bar .progress-bar span {
  font-weight: bold; /* Make the number within the progress bar bold */
}

.pwp-image {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 300px; /* Adjust as needed */
  height: auto;
  z-index: 0; /* Update this line */
}

h3 {
      font-size: 1.5rem;
  }

h1 {
    margin-top: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
